<!-- 封装表单 -->
<template>
  <div class="form">
    <slot></slot>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import AsyncValidator from 'async-validator'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    model: {
      type: Object
    },
    rule: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    //这里存放数据
    return {
      formItems: []
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.calcFormItems()
        }
      },
      deep: true
    }
  },
  //方法集合
  methods: {
    calcFormItems() {
      console.log(this.$slots.default)
      this.$nextTick(() => {
        if (this.$slots.default) {
          let children = this.$slots.default
            .filter(vnode => {
              return (
                vnode.tag &&
                vnode.componentOptions &&
                vnode.componentOptions.tag === 'form-item'
              )
            })

            .map(({ componentInstance }) => componentInstance)

          if (
            !(
              children.length === this.formItems.length &&
              children.every((pane, index) => pane === this.formItems[index])
            )
          ) {
            this.formItems = children
          }
          // console.log(this.formItems)
        }
      })
    },
    validate() {
      let validator = new AsyncValidator(this.rule)
      console.log(validator)
      let isSuccess = true

      let findErrorByProp = (errors, prop) => {
        return (
          errors.find(error => {
            return error.field === prop
          }) || ''
        )
      }
      console.log(findErrorByProp)

      validator.validate(this.model, (errors, fields) => {
        this.formItems.forEach(formItem => {
          let prop = formItem.prop
          let error = findErrorByProp(errors || [], prop)
          if (error) {
            isSuccess = false
          }

          formItem.showError((error && error.message) || '')
        })
      })

      return Promise.resolve(isSuccess)
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.calcFormItems()

    // console.log(this.model)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
.form {
  height: 100%;
}
</style>