<!-- 封装表单 -->
<template>
  <label class="form-item">
    <div class="form-item_label">{{label}}：</div>
    <div class="form-item_mn">
      <slot></slot>
    </div>
    <div class="form-item_error" v-if="errorMsg">
      <svg-icon iconClass="error"></svg-icon>
      {{errorMsg}}
    </div>
  </label>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    label: String,
    prop: String
  },
  data() {
    //这里存放数据
    return {
      errorMsg: ''
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    showError(msg) {
      this.errorMsg = msg
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
.form-item {
  display: block;
  margin-bottom: 10px;
  margin-left: 30%;
  /* margin: 10px auto; */
}
.form-item_label {
  display: inline-block;
  width: 150px;
  vertical-align: middle;
  text-align: right;
}
.form-item_mn {
  display: inline-block;
  vertical-align: middle;
}
.form-item_error {
  display: inline;
}
</style>