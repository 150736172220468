<!--企业注册页面  -->
<template>
  <div class="wrap">
    <!-- 零工注册 -->
    <div v-if="edition == 1">
      <div class="wrap_title">零工企业注册</div>
      <form-cpt :model="formData" :rule="rules" ref="baseForm">
        <form-item label="需求类型">
          <el-select
            v-model="formData.odd_job_status"
            placeholder="请选择需求类型"
            style="width: 250px"
            @change="change_select"
          >
            <el-option
              v-for="(item, index) in demand"
              :key="item"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </form-item>
        <form-item
          v-if="formData.odd_job_status !== ''"
          :label="formData.odd_job_status == 0 ? '公司名称' : '姓名'"
          prop="license_company_name"
        >
          <input
            class="ipt"
            type="text"
            v-model.trim="formData.license_company_name"
          />
        </form-item>

        <form-item
          v-if="formData.odd_job_status === 0"
          label="店铺名/品牌名/公司简称"
          prop="contact_name"
        >
          <input class="ipt" type="text" v-model.trim="Shopname" />
        </form-item>
        <form-item
          v-if="formData.odd_job_status === 0"
          label="联系人员"
          prop="contact_name"
        >
          <input class="ipt" type="text" v-model.trim="formData.contact_name" />
        </form-item>

        <form-item label="手机号码" prop="username">
          <input class="ipt" type="text" :maxlength="11" v-model.number="formData.username" />
        </form-item>
        <form-item label="验证码" prop="code">
          <input class="ipt-code" type="text" v-model.trim="formData.code" />
          <button class="send-code sending" v-if="sending">
            重新发送{{ sendTime }}秒
          </button>
          <button class="send-code" @click="sendCode" v-else>发送验证码</button>
        </form-item>
        <form-item label="设置密码" prop="password">
          <input class="ipt" type="password" v-model.trim="formData.password" />
        </form-item>
        <form-item label="确认密码">
          <input class="ipt" type="password" v-model.trim="confirm_password" />
        </form-item>
        <form-item label="城市选择">
          <el-cascader
            v-model="city"
            placeholder="北京"
            :options="optionsCity"
          ></el-cascader>
          <!--@change="handleChange"  -->
        </form-item>

        <form-item label="详细地址" v-if="formData.odd_job_status == 1 || formData.odd_job_status == 2">
          <input class="ipt" type="text" v-model.number="formData.detailed_address" />
        </form-item>

        <div style="display: flex" v-if="formData.odd_job_status === 0">
          <label class="form-item_label">营业执照：</label>
          <el-upload
            class="upload"
            :class="{ disable: imagesMax }"
            :on-success="successFile"
            :file-list="fileList"
            :on-remove="OnremoveHandler"
            action="/api/hr24_ee/user/v1/files_upload/"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>

        <div
          style="display: flex; margin-bottom: 10px"
          v-if="formData.odd_job_status === 1 || formData.odd_job_status === 2"
        >
          <label class="form-item_label">身份证人面像：</label>
          <el-upload
            class="upload"
            :class="{ disable: imagesMax_font }"
            :on-success="
              (response, file, fileList) =>
                successFile(
                  response,
                  file,
                  fileList,
                  'id_card_font',
                  'fileList_font',
                  'imagesMax_font'
                )
            "
            :file-list="fileList_font"
            :on-remove="
              (file, fileList) =>
                OnremoveHandler(
                  file,
                  fileList,
                  'id_card_font',
                  'fileList_font',
                  'imagesMax_font'
                )
            "
            action="/api/hr24_ee/user/v1/files_upload/"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div
          style="display: flex"
          v-if="formData.odd_job_status === 1 || formData.odd_job_status === 2"
        >
          <label class="form-item_label">身份证国徽像：</label>
          <el-upload
            class="upload"
            :class="{ disable: imagesMax_back }"
            :on-success="
              (response, file, fileList) =>
                successFile(
                  response,
                  file,
                  fileList,
                  'id_card_back',
                  'fileList_back',
                  'imagesMax_back'
                )
            "
            :file-list="fileList_back"
            :on-remove="
              (file, fileList) =>
                OnremoveHandler(
                  file,
                  fileList,
                  'id_card_back',
                  'fileList_back',
                  'imagesMax_back'
                )
            "
            action="/api/hr24_ee/user/v1/files_upload/"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>

        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>

        <div class="register" @click="odd_job_submit">注册</div>
        <!-- <div class="register" @click="$router.replace('/login')">退出</div> -->
      </form-cpt>
    </div>
    <!-- 全职注册 -->
    <div v-else>
      <div class="wrap_title">全职企业注册</div>
      <form-cpt :model="formData" :rule="rules" ref="baseForm">
        <form-item label="公司名称" prop="license_company_name">
          <input
            class="ipt"
            type="text"
            v-model.trim="formData.license_company_name"
          />
        </form-item>
        <form-item label="联系人员" prop="contact_name">
          <input class="ipt" type="text" v-model.trim="formData.contact_name" />
        </form-item>
        <form-item label="手机号码" prop="username">
          <input class="ipt" type="text" v-model.number="formData.username" />
        </form-item>
        <form-item label="验证码" prop="code">
          <input class="ipt-code" type="text" v-model.trim="formData.code" />
          <button class="send-code sending" v-if="sending">
            重新发送{{ sendTime }}秒
          </button>

          <button class="send-code" @click="sendCode" v-else>发送验证码</button>
        </form-item>

        <form-item label="设置密码" prop="password">
          <input class="ipt" type="password" v-model.trim="formData.password" />
        </form-item>
        <form-item label="确认密码">
          <input class="ipt" type="password" v-model.trim="confirm_password" />
        </form-item>
        <form-item label="城市选择">
          <el-cascader
            v-model="city"
            placeholder="北京"
            :options="optionsCity"
          ></el-cascader>
          <!--@change="handleChange"  -->
        </form-item>

        <div style="display: flex">
          <label class="form-item_label">营业执照：</label>
          <el-upload
            class="upload"
            :class="{ disable: imagesMax }"
            :on-success="successFile"
            :file-list="fileList"
            :on-remove="OnremoveHandler"
            action="/api/hr24_ee/user/v1/files_upload/"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>

        <div class="register" @click="submit">注册</div>
        <!-- <div class="register" @click="$router.replace('/login')">退出</div> -->
      </form-cpt>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import formCpt from "./../../../components/Form/form";
import axios from "axios";
import formItem from "./../../../components/Form/formItem";
import {
  register,
  sendCodeApi,
  isTrueCodeApi,
  check_code,
  odd_register,
} from "@/api/login";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { formItem, formCpt },
  data() {
    //这里存放数据
    return {
      demand: ["餐饮兼职", "家政服务", "学习辅导"],

      edition: 0, //是否为零工账号注册 0是全职 1是零工
      fileList: [],
      city: "",
      optionsCity: [],
      imagesMax: false,
      noproblemArr: [],
      submitStatus: false,
      isFinish: 0,
      //图片路径
      dialogImageUrl: "",
      //是否显示el-dialog
      dialogVisible: false,
      //全部数据
      formData: {
        //公司名称
        license_company_name: "",
        //联系人员
        contact_name: "",

        //验证码
        code: "",
        //帐号
        username: "",
        //密码
        password: "",
        license: "",
        odd_job_status: "",

        detailed_address: '',
        id_card_font: "",
        id_card_back: "",
      },
      //确认密码
      confirm_password: "",

      // 店铺名/品牌名/公司简称
      Shopname: "",

      rules: {
        license_company_name: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "联系人员不能为空", trigger: "blur" },
        ],

        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        username: [
          { type: "number", message: "手机号应该为数字值" },

          {
            required: true,
            message: "手机号码不能为空",
            trigger: "blur",
            type: "number",
          },
          // pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9]|17[0-9])[0-9]{8}$/,
          {
            pattern: /^1{1}[3456789]{1}[0-9]{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
      //验证码发送中
      sending: false,
      sendTime: 60,

      //

      fileList_font: [],
      fileList_back: [],
      imagesMax_font: false,
      imagesMax_back: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    // formData: {
    //   handler: function(val, oldval) {
    //     console.log(val)
    //   },
    //   deep: true //对象内部的属性监听，也叫深度监听
    // }
  },
  //方法集合
  methods: {
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error("上传营业执照图片只能是 JPG、PNG 格式!");
      }
      return isJPG;
    },
    //文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //发送验证码
    sendCode() {
      if (!/^1{1}[3456789]{1}[0-9]{9}$/.test(this.formData.username)) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
      } else {
        this.sending = true;
        this.sendTime = 60;
        let a = setInterval(() => {
          if (this.sendTime === 0) {
            this.sending = false;
            clearInterval(a);
            a = null;

            return;
          }
          console.log(a);
          this.sendTime--;
          console.log(this.sendTime);
        }, 1000);

        sendCodeApi({ status: 0, phone: this.formData.username }).then(
          (res) => {
            console.log(res);
          }
        );
      }
    },
    //注册
    submit() {
      console.log(this.city);
      console.log(this.confirm_password);
      if (this.confirm_password === "") {
        this.$message({
          message: "不能为空",
          type: "warning",
        });
        return;
      }

      if (this.formData.password != this.confirm_password) {
        this.$message({
          message: "两次密码不一致",
          type: "warning",
        });
        return;
      }

      console.log(this.formData);
      this.$refs.baseForm
        .validate()
        .then((res) => {
          if (res) {
            console.log(this.city);
            if (Array.isArray(this.city)) {
              if ((this.city.length = 3)) {
                this.formData.province_id = Number(this.city[0]);
                this.formData.city_id = Number(this.city[1]);
                this.formData.district_id = Number(this.city[2]);
              } else {
                // this.formData.province_id = Number(this.city[0])
                this.formData.city_id = Number(this.city[0]);
                this.formData.district_id = Number(this.city[1]);
              }
            } else {
              this.$message({
                message: "请选择城市",
                type: "warning",
              });
              return;
            }
            if (this.formData.license === "") {
              this.$message({
                message: "请上传营业执照",
                type: "warning",
              });
              return;
            }

            check_code({
              phone: this.formData.username,
              code: this.formData.code,
            }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                register(this.formData).then((res) => {
                  if (res.code === 0) {
                    this.$message({
                      message: res.msg,
                      type: "success",
                    });
                    this.$router.go(-1);
                  } else {
                    this.$message({
                      message: res.msg,
                      type: "error",
                    });
                  }
                });
              } else {
                this.$message.warning(res.msg);
              }
            });
          }
        })
        .catch((e) => {
          // console.log(e)
        });
    },

    // 零工注册
    odd_job_submit() {
      console.log("123123");
      this.formData["company_short_name"] = this.Shopname;
      if (this.formData.odd_job_status === "") {
        this.$message({
          message: "请选择需求类型",
          type: "warning",
        });
        return;
      }
      if ( this.formData.company_short_name == "" && this.formData.odd_job_status == 0 ) {
        this.$message({
          message: "店铺名/品牌名/公司简称不能为空",
          type: "warning",
        });
        return;
      }
      if ( this.formData.detailed_address == "" && this.formData.odd_job_status != 0 ) {
        this.$message({
          message: "请填写详细地址",
          type: "warning",
        });
        return;

      }
      if ( this.formData.id_card_font == "" && this.formData.odd_job_status != 0 ) {
        this.$message({
          message: "请上身份证人面像",
          type: "warning",
        });
        return;

      }
      if ( this.formData.id_card_back == "" && this.formData.odd_job_status != 0 ) {
        this.$message({
          message: "请上传身份证国徽像",
          type: "warning",
        });
        return;

      }

      // console.log(this.city);
      // console.log(this.confirm_password);
      if (this.confirm_password === "") {
        this.$message({
          message: "密码不能为空",
          type: "warning",
        });
        return;
      }

      if (this.formData.password != this.confirm_password) {
        this.$message({
          message: "两次密码不一致",
          type: "warning",
        });
        return;
      }

      this.$refs.baseForm
        .validate()
        .then((res) => {
          if (res) {
            console.log(this.city);
            if (Array.isArray(this.city)) {
              if ((this.city.length = 3)) {
                this.formData.province_id = Number(this.city[0]);
                this.formData.city_id = Number(this.city[1]);
                this.formData.district_id = Number(this.city[2]);
              } else {
                // this.formData.province_id = Number(this.city[0])
                this.formData.city_id = Number(this.city[0]);
                this.formData.district_id = Number(this.city[1]);
              }
            } else {
              this.$message({
                message: "请选择城市",
                type: "warning",
              });
              return;
            }
            if (this.formData.license === "" && this.formData.odd_job_status == 0) {
              this.$message({
                message: "请上传营业执照",
                type: "warning",
              });
              return;
            }

            check_code({
              phone: this.formData.username,
              code: this.formData.code,
            }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                odd_register(this.formData).then((res) => {
                  if (res.code === 0) {
                    this.$message({
                      message: res.msg,
                      type: "success",
                    });
                    this.$router.go(-1);
                  } else {
                    this.$message({
                      message: res.msg,
                      type: "error",
                    });
                  }
                });
              } else {
                this.$message.warning(res.msg);
              }
            });
          }
        })
        .catch((e) => {
          // console.log(e)
        });
    },
    //城市选择
    // handleChange(value) {
    //   console.log(value)
    // },
    //删除图片
    OnremoveHandler(
      file,
      fileList,
      license = "license",
      fileList_ = "fileList",
      imagesMax = "imagesMax"
    ) {
      this.formData[license] = "";
      console.log(fileList_);
      this[fileList_] = [];
      this[imagesMax] = fileList.length >= 1 ? true : false;
    },
    //上传图片
    successFile(
      response,
      file,
      fileList,
      license = "license",
      fileList_ = "fileList",
      imagesMax = "imagesMax"
    ) {
      this.formData[license] = response.file_id;
      this[fileList_] = [{ name: response.file_id, url: response.file_url }];
      this[imagesMax] = fileList.length >= 1 ? true : false;
    },

    change_select(e) {
      if (e == 0) {
        this.rules = {
          license_company_name: [
            { required: true, message: "公司名称不能为空", trigger: "blur" },
          ],
          contact_name: [
            { required: true, message: "联系人员不能为空", trigger: "blur" },
          ],

          code: [
            { required: true, message: "验证码不能为空", trigger: "blur" },
          ],
          username: [
            { type: "number", message: "手机号应该为数字值" },

            {
              required: true,
              message: "手机号码不能为空",
              trigger: "blur",
              type: "number",
            },
            // pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9]|17[0-9])[0-9]{8}$/,
            {
              pattern: /^1{1}[3456789]{1}[0-9]{9}$/,
              message: "手机号格式不对",
              trigger: "blur",
            },
          ],
          password: [
            { required: true, message: "密码不能为空", trigger: "blur" },
            {
              min: 6,
              max: 16,
              message: "长度在 6 到 16 个字符",
              trigger: "blur",
            },
          ],
        };
      } else {
        this.rules = {
          license_company_name: [
            { required: true, message: "姓名不能为空", trigger: "blur" },
          ],
          code: [
            { required: true, message: "验证码不能为空", trigger: "blur" },
          ],
          username: [
            { type: "number", message: "手机号应该为数字值" },
            {
              required: true,
              message: "手机号码不能为空",
              trigger: "blur",
              type: "number",
            },
            // pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9]|17[0-9])[0-9]{8}$/,
            {
              pattern: /^1{1}[3456789]{1}[0-9]{9}$/,
              message: "手机号格式不对",
              trigger: "blur",
            },
          ],
          password: [
            { required: true, message: "密码不能为空", trigger: "blur" },
            {
              min: 6,
              max: 16,
              message: "长度在 6 到 16 个字符",
              trigger: "blur",
            },
          ],
        };
      }
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    axios.get("/static/cn.json").then((res) => {
      console.log(res);
      this.optionsCity = res;
    });
    this.edition = this.$route.query.edition;
    console.log(this.edition);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* //@import url(); 引入公共css类 */
/* input style */
.wrap {
  /* width: 100%; */
  margin: 100px auto 0;
  width: 1000px;
  box-sizing: content-box;
  border-left: 1px solid #ccc;

  border-right: 1px solid #ccc;
  /* border-left: 1px solid #ccc; */

  /* background: linear-gradient(
    45deg,
    rgb(255, 100, 38) 1%,
    rgb(255, 255, 255) 40%
  ); */
}

.wrap_title {
  font-size: 23px;
  color: #666;
  text-align: center;
  margin-bottom: 46px;
}

.register {
  width: 100px;
  height: 40px;
  background-color: #ff8f00;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 40px;
  margin: 0 auto;
  /* margin-right: ; */

  margin-top: 50px;
  cursor: pointer;
}
.form /deep/ .form-item_label {
  width: 185px;
}
.form /deep/ .form-item {
  margin-left: 26%;
}
.form-item_label {
  display: inline-block;
  width: 150px;
  margin-left: 26%;

  text-align: right;
}
.upload {
}
.ipt {
  display: inline-block;
  height: 40px;
  width: 250px;
  box-sizing: border-box;

  box-shadow: 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-radius: 4px;
  /* text-indent: 2rem; */
  padding: 0 30px 0 15px;
  font-size: 17px;
  color: #666;
}
.ipt-code {
  display: inline-block;
  height: 40px;
  width: 150px;

  box-shadow: 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  /* text-indent: 2rem; */
  padding: 0 30px 0 15px;
  font-size: 17px;
  color: #666;
  vertical-align: middle;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.send-code {
  color: #fff;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  /* border: 1px solid red; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 40px;
  width: 100px;
  background-color: #ff8f00;

  cursor: pointer;
}
.send-code.sending {
  background-color: #ccc;
  color: #ff8f00;
}
.disable /deep/ .el-upload--picture-card {
  display: none;
}
div /deep/ .el-cascader .el-input {
  width: 250px;
}
</style>
